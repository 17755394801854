/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
// any CSS you import will output into a single css file (app.css in this case)
//import './scss/app.scss';
import './styles/app.scss';
import './styles/app.css';
//require('../styles/app.scss');
// start the Stimulus application
//import './bootstrap';
const $ = require('jquery');
require('bootstrap');
require('croppie');

require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.en-AU.min');
// css
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css');
'use strict';
//https://bootstrap-datepicker.readthedocs.io/en/stable/options.html

require('pc-bootstrap4-datetimepicker');
require('pc-bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min.js');
// css
require('pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css');

//http://eonasdan.github.io/bootstrap-datetimepicker/ with a bit of editing for awesomefont
$.extend(true, $.fn.datetimepicker.defaults, {
    icons: {
        time: 'fa fa-cog',
        date: 'fa fa-cog',
        up: 'fa fa-cog',
        down: 'fa fa-cog',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check',
        clear: 'far fa-trash-alt',
        close: 'far fa-times-circle'
    }
});

$(document).ready(function() {
    $('.datetimepicker').datetimepicker({
        format : "mm/dd/yyyy hh:ii",
        autoclose : true,
        todayBtn: "linked",
        todayHighlight: true
    });
});

$(function () {
    $('.tadatepicker').datepicker({
        format: 'mm/dd/yyyy',
        startDate: '-3d'
    });
});



$(function () {
    $('#datetimepicker1').datetimepicker({
        icons: {
            time: 'fa fa-cog',
            date: 'fa fa-cog',
            up: 'fa fa-cog',
            down: 'fa fa-cog',
            previous: 'fa fa-cog',
            next: 'fa fa-cog',
            today: 'fa fa-cog',
            clear: 'far fa-trash-alt',
            close: 'far fa-times-circle',
            orientation: "bottom"
        }
    });
});

$(function () {
    $('#datetimepicker2').datetimepicker({
        icons: {
            time: "oi oi-timer icon",
            date: "oi oi-calendar icon",
            up: "oi oi-arrow-top icon",
            down: "oi oi-arrow-bottom icon",
            previous: "oi oi-arrow-left icon",
            next: "oi oi-arrow-right icon",
            today: "oi oi-people icon",
            clear: "oi oi-people icon",
            close: "oi oi-people icon",
            orientation: "bottom"
        }
    });
});

$(function () {
    $('#datetimepicker3').datetimepicker({
        icons: {
            time: "oi oi-timer icon",
            date: "oi oi-calendar icon",
            up: "oi oi-arrow-top icon",
            down: "oi oi-arrow-bottom icon",
            previous: "oi oi-arrow-left icon",
            next: "oi oi-arrow-right icon",
            today: "oi oi-people icon",
            clear: "oi oi-people icon",
            close: "oi oi-people icon",
            orientation: "bottom"
        }
    });
});

$(function () {
    $('#datetimepicker4').datetimepicker({
        icons: {
            time: "oi oi-timer icon",
            date: "oi oi-calendar icon",
            up: "oi oi-arrow-top icon",
            down: "oi oi-arrow-bottom icon",
            previous: "oi oi-arrow-left icon",
            next: "oi oi-arrow-right icon",
            today: "oi oi-people icon",
            clear: "oi oi-people icon",
            close: "oi oi-people icon",
            orientation: "bottom"
        }
    });
});

$(function () {
    $('#datetimepicker5').datetimepicker({
        icons: {
            time: "oi oi-timer icon",
            date: "oi oi-calendar icon",
            up: "oi oi-arrow-top icon",
            down: "oi oi-arrow-bottom icon",
            previous: "oi oi-arrow-left icon",
            next: "oi oi-arrow-right icon",
            today: "oi oi-people icon",
            clear: "oi oi-people icon",
            close: "oi oi-people icon",
            orientation: "bottom"
        }
    });
});

$(function () {
    $('.datetimepicker4').datetimepicker();
});

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

/*
//new page layout this killed the datepicker below
const themeMap = {
    dark: "light",
    light: "solar",
    solar: "dark"
};

const theme = localStorage.getItem('theme')
    || (tmp = Object.keys(themeMap)[0],
        localStorage.setItem('theme', tmp),
        tmp);
const bodyClass = document.body.classList;
bodyClass.add(theme);

function toggleTheme() {
    const current = localStorage.getItem('theme');
    const next = themeMap[current];

    bodyClass.replace(current, next);
    localStorage.setItem('theme', next);
}

document.getElementById('themeButton').onclick = toggleTheme;
//page layout end
*/

/*Used in Db/View/DbAlpha_List.html.twig https://stackoverflow.com/questions/659508/how-can-i-shift-select-multiple-checkboxes-like-gmail/659571#659571*/
$(document).ready(function() {
    var $chkboxes = $('.chkbox');
    var lastChecked = null;

    $chkboxes.click(function(e) {
        if (!lastChecked) {
            lastChecked = this;
            return;
        }

        if (e.shiftKey) {
            var start = $chkboxes.index(this);
            var end = $chkboxes.index(lastChecked);

            $chkboxes.slice(Math.min(start,end), Math.max(start,end)+ 1).prop('checked', lastChecked.checked);
        }

        lastChecked = this;
    });
});


$(document).ready(function() {
    $('.datepicker').datepicker({
        format : "mm/dd/yyyy",
        autoclose : true,
        todayBtn: "linked",
        todayHighlight: true
    });
});

$(document).ready(function() {
    $('#datepicker').datepicker({
        format : "mm/dd/yyyy",
        autoclose : true,
        todayBtn: "linked",
        todayHighlight: true
    });
});

$(document).ready(function() {
    $('.datepickeryear').datepicker({
        format: "yyyy",
        viewMode: "years",
        minViewMode: "years",
        autoclose : true
    });
});
///maps


///maps
//theme https://www.youtube.com/watch?v=biOMz4puGt8
const themeMap = {
    light: "solar",
    solar: "my_journey",
    my_journey: "dark",
    dark: "light"
};

let tmp;
const theme = localStorage.getItem('theme')
    || (tmp = Object.keys(themeMap)[0],
        localStorage.setItem('theme', tmp),
        tmp);
const bodyClass = document.body.classList;
bodyClass.add(theme);

function toggleTheme() {
    const current = localStorage.getItem('theme');
    const next = themeMap[current];
    bodyClass.replace(current, next);
    localStorage.setItem('theme', next);
}
document.getElementById('themeButton').onclick = toggleTheme;

// right navigation
$('nav-right a').on('click', function() {
    const scrollAnchor = $(this).attr('data-scroll'),
        scrollPoint = $('section[data-anchor="' + scrollAnchor + '"]').offset().top - 28;
    $('body,html').animate({
        scrollTop: scrollPoint
    }, 500);
    return false;
})


$(window).scroll(function() {
    const windscroll = $(window).scrollTop();
    if (windscroll >= 0) {
        $('nav-right').addClass('fixed-right');
        $('.wrapper-right section').each(function(i) {
            if ($(this).position().top <= windscroll - 20) {
                $('nav-right a.active').removeClass('active');
                $('nav-right a').eq(i).addClass('active');
            }
        });
    } else {
        $('nav-right').removeClass('fixed-right');
        $('nav-right a.active').removeClass('active');
        $('nav-right a:first').addClass('active');
    }

}).scroll();
// right navigation
//font awesome npm install --save-dev @fortawesome/fontawesome-free

//tool tips
import { createPopper } from '@popperjs/core';
const popcorn = document.querySelector('#popcorn');
const tooltip = document.querySelector('#tooltip');
createPopper(popcorn, tooltip, {
    placement: 'right',
});